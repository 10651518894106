footer {
  width: 100%;
  background: #3c4258;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.mainFooter {
  display: flex;
  justify-content: space-between;
  /* justify-content: space-around; */
  align-items: center;
  width: 95vw;
  border-top: 1px solid #06f6a6;
}