.App-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 50vw;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .App-header {
    max-width: 100vw;
  }
}