/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
Custom CSS
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

* {
  box-sizing: border-box;
}

body {
  background-color: #3c4258;
  color: white;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
}

main {
  width: 100%;
  max-width: 800px;
  margin-bottom: 100px;
}

.cstmDivider {
 border-bottom: 1px solid #fff;
}
.cstmDividerPadding {
  margin: 8vh 0;
}

form {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.todoList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.todoListButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#todoListForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

#todoListForm input,
select {
  align-items: center;
  font-size: 1.2rem;
  background-color: #3c4258;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  padding: 20px 30px;
}

::placeholder {
  color: rgb(235, 235, 235);
}

.addItemInput {
  background-color: transparent;
  width: 100%;
  height: 67px;
  flex: 1;
  margin-right: 3vw;
  line-height: 30px;
}

.text-strike {
  text-decoration: line-through;
  opacity: 0.7;
}
.closeButton, .editButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 42px;
  width: 42px;
  padding: 10px;
  border-radius: 150%;
  transition: 0.3s all;
  touch-action: manipulation;
}
.closeButton:hover, .editButton:hover {
  transform: scale(1.1);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.spanH1Style {
  font-weight: 800;
  font-size: 20px;
  color: #06f6a6;
  letter-spacing: 4px;
}

@media only screen and (max-width: 1000px) {
  main {
    max-width: 90vw;
  }
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
Checkbox credit: https://codepen.io/valerypatorius/pen/oXGMGL
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

/*Checkboxes styles*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"] + label:last-child {
  display: flex;
}

input[type="checkbox"] + label:before {
  margin-right: 10px;
  content: "";
  min-width: 30px;
  width: 30px;
  height: 30px;
  border: 1px solid #a3e0fa;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

input[type="checkbox"]:checked + label:before {
  color: #3c4258;
  background-color: #a3e0fa;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "✓";
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
FORM and input
$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

/*INPUT*/
#userForm input {
  font-size: 1.2rem !important;
  padding: 20px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 30px;
}

/*BUTTON*/
.mainBtn {
  display: inline-block;
  border: none;
  border-radius: 100px;
  padding: 1rem 2rem;
  margin: 20px 20px 0 0;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  max-width: 250px;
  transition: 0.3s all;
}

.greenBtn {
  background: #00cf80;
  color: #3c4258;
}

.redBtn {
  color: #fff;
  background: #3c4258;
  box-shadow: 0px 0px 0px 1px #06f6a6;
}

.signOutBtn {
  color: #fff;
  font-size: 0.8rem;
  padding: 7px 15px;
  background: transparent;
  border: 1px solid #fff;
}
.signOutBtn:hover {
  color: #3c4258;
  background: #fff;
  border: 1px solid #3c4258;
}

.mainBtn:hover,
.mainBtn:focus {
  box-shadow: 0px 0px 0px 1px #fff;
}

.circleBtn {
  border-radius: 100%;
  width: 67px;
  height: 67px;
  margin: 0;
}